<template>
  <div>
    <div
      v-if="loadingStatus || isLoading === true"
      class="col-span-12 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">{{ message }}</div>
    </div>
    <div v-if="isSuccess === false && useState === true" class="mt-2">
      <AlertFailed
        :message="responseMessage"
        :dismissable="true"
        @closeAndClear="closeAndClear"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AlertFailed from "../alerts/AlertFailed";
export default defineComponent({
  name: "LoadingSpinner",
  components: { AlertFailed },
  props: {
    isLoading: {
      type: Boolean,
      default: null,
    },
    useState: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: "Loading data... Please Wait...",
    },
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    responseMessage() {
      return this.$store.state.main.responseMessage !== ""
        ? this.$store.state.main.responseMessage
        : null;
    },
  },
  mounted() {},
  methods: {
    closeAndClear() {
      this.$store.dispatch("main/responseMessage", "");
      this.$store.dispatch("main/isSuccess", null);
    },
  },
});
</script>
