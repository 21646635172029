<template>
  <div>
    <select
      v-if="currentInstitusi?.ada_fakultas === 1"
      v-model="faculty"
      :class="cssclass"
      @change="setCurrent"
    >
      <option
        v-for="fak in faculties"
        :key="fak.id"
        :value="fak.value"
        :selected="fak.selected"
      >
        {{ fak.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { master } from "@/services/models";
import { userdata } from "@/utils/userdata";

export default {
  name: "FakultasSelector",
  props: {
    cssclass: {
      type: String,
      default: "w-auto form-select box md:w-full",
    },
  },
  data() {
    return {
      faculties: [],
      faculty: 0,
      current: null,
    };
  },
  computed: {
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
  },
  mounted() {
    this.current = this.$store.state.main.currentFakultas;
    if (!userdata.isAuthenticated()) return;
    this.fetchFakultas();
  },
  methods: {
    setCurrent() {
      const cfac = this.faculty;
      let curr = this.faculties.filter((item) => item.id === cfac);
      this.$store.dispatch("main/currentFakultas", curr[0]);
    },
    fetchFakultas() {
      let that = this;
      this.faculties.push({
        id: "0",
        value: "0",
        text: "Pilih Fakultas",
        selected: false,
      });
      master.MasterFakultas.getAllActive().then((result) => {
        if (result?.status === 200) {
          result?.data?.data.forEach((item) => {
            let newoption = {
              id: item.id,
              value: item.id,
              text: item.nama,
              kode_pddikti: item.kode_pddikti,
              nama: item.nama,
              nama_alias: item.nama_alias,
              id_sp: item.id_sp,
              selected: that.current?.id === item.id,
            };
            if (newoption.selected === true) that.faculty = item.id;
            that.faculties.push(newoption);
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
