<template>
  <div>
    <div class="intro-y box p-3 my-3">
      <div id="filter-accordion" class="accordion z-0">
        <div class="accordion-item z-0">
          <div id="filter-prodi-content" class="accordion-header z-0">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filter-prodi-collapse"
              aria-expanded="true"
              aria-controls="filter-prodi-collapse"
            >
              Pilih Program Studi
            </button>
          </div>
          <div
            id="filter-prodi-collapse"
            class="accordion-collapse collapse show z-0"
            aria-labelledby="filter-prodi-content"
            data-bs-parent="#filter-accordion"
          >
            <div
              class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
            >
              <ol>
                <li v-for="prodi in listActiveProdi" :key="prodi.id">
                  <a
                    v-if="
                      (currentInstitusi?.id == prodi.id_induk &&
                        prodi.id_fakultas == null) ||
                      (currentInstitusi?.id == prodi.id_induk &&
                        prodi.id_fakultas == currentFakultas?.id)
                    "
                    class="text-gray-700 dark:text-gray-600 block font-normal cursor-pointer"
                    @click="setId(prodi.id)"
                  >
                    <ChevronsRightIcon />
                    {{ prodi.jenjang + " " + prodi.nama }}
                    <span
                      v-if="
                        filterData?.prodi.data.filter(
                          (item) => item.id === prodi.id
                        ).length > 0
                      "
                      class="bg-theme-17 text-theme-11 w-4 rounded px-2 py-0.5"
                    >
                      F
                    </span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ListProdiFilter",
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { id: null };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
    listProdi() {
      return this.$store.getters[`MasterProdi/list`];
    },
    listActiveProdi() {
      let allprodi = this.$store.getters[`MasterProdi/list`];
      return allprodi.filter((prodi) => prodi?.status === 1);
    },
    currentFakultas() {
      return this.$store.state.main.currentFakultas;
    },
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
  },
  watch: {
    currentInstitusi: {
      handler: function () {
        this.$store.dispatch("MasterProdi/all");
      },
    },
  },
  mounted() {
    this.$store.dispatch("MasterProdi/all");
  },
  methods: {
    setId(id) {
      this.id = id;
      this.$emit("filter", id);
    },
  },
});
</script>

<style scoped></style>
