<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M384 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V64C448 28.65 419.3 0 384 0zM64 112C64 103.3 71.25 96 80 96h32C120.8 96 128 103.3 128 112v32C128 152.8 120.8 160 112 160h-32C71.25 160 64 152.8 64 144V112zM128 400C128 408.8 120.8 416 112 416h-32C71.25 416 64 408.8 64 400v-32C64 359.3 71.25 352 80 352h32C120.8 352 128 359.3 128 368V400zM163.3 227.3l-56 56C104.2 286.4 100.1 288 96 288S87.81 286.4 84.69 283.3l-24-24c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L96 249.4l44.69-44.69c6.25-6.25 16.38-6.25 22.62 0S169.6 221.1 163.3 227.3zM368 400h-160C199.2 400 192 392.8 192 384s7.164-16 16-16h160c8.836 0 16 7.164 16 16S376.8 400 368 400zM368 272h-160C199.2 272 192 264.8 192 256s7.164-16 16-16h160C376.8 240 384 247.2 384 256S376.8 272 368 272zM368 144h-160C199.2 144 192 136.8 192 128s7.164-16 16-16h160C376.8 112 384 119.2 384 128S376.8 144 368 144z"
    />
  </svg>
</template>
