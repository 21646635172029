<template>
  <MainLayout :show-sync="true" :modal-size="'modal-xl'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Peserta Didik<br />
          <span class="text-sm font-medium" data-v-f48bdcdc=""
            >Daftar Mahasiswa Perguruan Tinggi</span
          >
        </h2>

        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
            v-if="syncOldDataConfig === 'Y'"
            :to="{ name: 'sync-pesertadidik' }"
            class="btn btn-warning shadow-md mr-2"
            >Sync Data Reg. PD
          </router-link>

          <!--          <router-link
            v-if="syncOldDataConfig === 'Y'"
            :to="{ name: 'import-mahasiswa' }"
            class="btn btn-warning shadow-md mr-2"
            >Import Mhs</router-link
          >-->
          <button class="btn btn-default shadow-md" @click="getItems">
            <arrowRotateRight class="w-4 h-4" />
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
            <div class="sm:flex items-center sm:mr-4">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field</label
              >
              <select
                id="tabulator-html-filter-field"
                v-model="filter.field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="nipd">NPM</option>
                <option value="peserta_didik.nm_pd">Nama</option>
                <option value="mulai_smt">Semester Mulai</option>
                <option value="tgl_masuk_sp">Tahun Masuk</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Value</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="filter.value"
                type="text"
                class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-16"
                @click="onFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </form>
          <div class="flex mt-5 sm:mt-0">
            <button
              id="tabulator-print"
              class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
              @click="onPrint"
            >
              <PrinterIcon class="w-4 h-4 mr-2" /> Print
            </button>
            <div class="dropdown w-1/2 sm:w-auto">
              <button
                class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                aria-expanded="false"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    id="tabulator-export-csv"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportCsv"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                  </a>
                  <a
                    id="tabulator-export-json"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportJson"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                  </a>
                  <a
                    id="tabulator-export-xlsx"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportXlsx"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                  </a>
                  <a
                    id="tabulator-export-html"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportHtml"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->

      <!-- BEGIN: Modal Content -->
      <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                <div class="text-3xl mt-5">Are you sure?</div>
                <div class="text-gray-600 mt-2">
                  Do you really want to delete these records? <br />
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-danger w-24"
                  @click="deleteRow"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Modal Content -->
    </div>
    <template #filterside>
      <InstitusiSelector />
      <FakultasSelector />
      <ListProdiFilter :filter-data="filterData" @filter="filterProdi" />
    </template>

    <template #syncmenu>
      <div class="mt-2">
        <TailSelectSelector
          :list-options="listOptionsState?.prodi"
          @cbox-select="selectSyncMhsSms"
        ></TailSelectSelector>
        <div class="flex">
          <div class="flex-1 mr-2">
            <input
              v-model="syncangkatan"
              type="text"
              :maxlength="4"
              class="form-control mb-2"
              placeholder="Angkatan"
            />
          </div>
          <div class="flex-1 mr-2">
            <input
              v-model="synclastnipd"
              type="number"
              :maxlength="4"
              class="form-control mb-2"
              placeholder="NIM Terakhir"
            />
          </div>
          <div class="flex-1 mr-2 mt-2">
            <div class="form-check">
              <input
                id="last-sync-error"
                v-model="synclasterror"
                class="form-check-switch"
                type="checkbox"
              />
              <label class="form-check-label" for="last-sync-error"
                >Sync Last Error</label
              >
            </div>
          </div>
          <div class="flex-initial">
            <button
              v-if="syncloading === false"
              class="btn btn-default"
              @click="resetSync(true)"
            >
              Reset
            </button>
            <button
              v-if="syncloading === false"
              class="btn btn-success ml-1"
              @click="syncMhs"
            >
              Sync PD
            </button>

            <button
              v-if="syncloading === false"
              class="btn btn-success ml-1"
              @click="syncRegPD"
            >
              Sync Reg. PD
            </button>
          </div>
        </div>

        <button
          v-if="syncloading === false && syncOldDataConfig === 'Y'"
          class="btn btn-warning btn-sm"
          @click="syncSimponi"
        >
          Update Biodata dari SIMPONI
        </button>
      </div>

      <LoadingSpinner :is-loading="syncloading === true" />
      <div v-if="sync === true" class="mt-4">
        <p>
          Sync <strong>{{ syncing }}</strong> dari
          <strong>{{ presyncData.length }}</strong> data.
          <span class="text-green-900">
            <strong>{{ syncsuccess }}</strong> sukses.
          </span>
          <span class="text-red-900">
            <strong>{{ syncfailed }}</strong> data gagal.
          </span>
        </p>
      </div>

      <div class="mt-2 text-yellow-900 max-h-72 overflow-y-auto">
        <p v-for="dt in presyncData" :key="dt.npm">
          {{ dt.npm }} : {{ dt?.result }}
        </p>
      </div>

      <div
        v-if="syncerrormsg.length > 0"
        class="mt-2 text-yellow-900 max-h-72 overflow-y-auto"
      >
        <p v-for="err in syncerrormsg" :key="err">{{ err }}</p>
      </div>

      <div class="text-red-900 mt-5">
        <ol>
          <li class="text-justify">
            Proses <strong>Sync PD</strong> akan mengupdate data Biodata Peserta
            Didik dan hanya akan mengirim data mahasiswa dengan status feeder
            <em><strong>need update</strong></em> dan error saat proses
            sinkronisai sebelumnya.
          </li>

          <li class="text-justify">
            Proses <strong>Sync Reg. PD</strong> akan mengupdate data Riwayat
            Pendidikan Mahasiswa.
          </li>
        </ol>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import { mahasiswa } from "@/services/models";
import { useStore } from "vuex";
import router from "@/router";
import { tblTabulator } from "@/utils/tabulator";
import MainLayout from "@/layouts/main/MainLayout";
import InstitusiSelector from "@/components/form/InstitusiSelector";
import FakultasSelector from "@/components/form/FakultasSelector";
import ListProdiFilter from "@/components/form/ListProdiFilter";
import TailSelectSelector from "../../../components/form/TailSelectSelector";
import LoadingSpinner from "../../../components/loading-spinner/Main";
import { option } from "@/utils/listoptions";
import { arrowRotateRight } from "@/assets/icons/customIcons";

export default defineComponent({
  components: {
    LoadingSpinner,
    TailSelectSelector,
    ListProdiFilter,
    FakultasSelector,
    InstitusiSelector,
    MainLayout,
    arrowRotateRight,
  },
  setup() {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "nipd",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    /*const editRow = (e, cell) => {
      idData.value = cell.getData().id;
      router.push({ name: "edit-pesertadidik", params: { id: idData.value } });
    };*/
    const viewRow = (e, cell) => {
      idData.value = cell.getData()?.id_pd;
      router.push({ name: "view-pesertadidik", params: { id: idData.value } });
    };
    const deleteRow = (e, cell) => {
      //e - the click event object
      cash("#delete-modal").modal("show");
      deletingRow.value = cell.getRow();
      idData.value = cell.getData().id;
    };
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "NPM",
          width: 100,
          field: "nipd",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
        },
        {
          title: "NAMA",
          width: 150,
          field: "peserta_didik",
          hozAlign: "left",
          vertAlign: "middle",
          headerSort: false,
          print: true,
          download: true,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-normal">${
                  cell.getData()?.peserta_didik?.nm_pd
                }
                <span class="text-xs text-gray-600">(${
                  cell.getData()?.peserta_didik?.jk == "L"
                    ? "Laki-Laki"
                    : "Perempuan"
                })
                </span>
                </div>

              </div>`;
          },
        },

        {
          title: "SMT MULAI",
          width: 150,
          field: "mulai_smt",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          formatter(cell) {
            return `${cell.getData().mulai_smt}`;
          },
        },

        {
          title: "ANGKATAN",
          width: 150,
          field: "tgl_masuk_sp",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          formatter(cell) {
            return `${cell.getData().tgl_masuk_sp.substring(0, 4)}`;
          },
        },
        {
          title: "PRODI",
          minWidth: 120,
          field: "sms",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let sms = cell.getData()?.sms;
            return `<div>
                  <div class="font-medium whitespace-nowrap">
                  <span class="text-gray-600"> ${
                    sms?.jenjang_pendidikan?.nm_jenj_didik
                  }</span>
                      ${sms?.nm_lemb}
                  </div>
                  <div class="text-gray-600 text-xs whitespace-normal">${
                    cell.getData()?.satuan_pendidikan?.nm_lemb
                  }</div>
              </div>`;
          },
        },
        {
          title: "JENIS DAFTAR",
          width: 150,
          field: "id_jns_daftar",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          formatter(cell) {
            return `<div>
                  <div class="text-gray-600 text-xs whitespace-normal">${
                    cell.getData()?.jenis_pendaftaran?.nm_jns_daftar
                  }</div>
              </div>`;
          },
        },

        /*{
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 70,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-primary items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>
              </div>`);
            return a[0];
          },
          cellClick: editRow
        },*/
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 70,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex space-x-2 items-center btn btn-sm btn-dark" href="javascript:;">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#fff" class="w-4 h-4"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z"/></svg>
                  </div>
                  <div>
                    Detail
                  </div>
                </a>
              </div>`);
            return a[0];
          },
          cellClick: viewRow,
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 40,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center text-theme-6" href="javascript:;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6" fill="#ef4444"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM160 128C151.2 128 144 135.2 144 144C144 152.8 151.2 160 160 160H352C360.8 160 368 152.8 368 144C368 135.2 360.8 128 352 128H310.6L299.3 116.7C296.3 113.7 292.2 112 288 112H224C219.8 112 215.7 113.7 212.7 116.7L201.4 128H160zM160 192L173.6 354.7C174.9 371.2 188.8 384 205.4 384H306.6C323.2 384 337.1 371.2 338.4 354.7L352 192H160z"/></svg>
                </a>
              </div>`);
            return a[0];
          },
          cellClick: deleteRow,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        mahasiswa.RegistasiPD.endpoint,
        columns
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "nipd";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      accessToken,
      deletingRow,
      idData,
    };
  },
  data() {
    return {
      syncOldDataConfig: process.env?.VUE_APP_SYNC_DATA_LAMA,
      deleting: false,
      editing: false,
      filterData: {
        prodi: {
          field: "id_sms",
          data: [],
        },
        kurikulum: {
          field: "id_kurikulum_sp",
          label: "Pilih Kurikulum",
          current: null,
          data: [],
        },
      },
      syncsuccess: 0,
      syncfailed: 0,
      syncing: 0,
      syncerrormsg: [],
      presyncData: [],
      sync: false,
      synclasterror: true,
      syncangkatan: null,
      synclastnipd: null,
      mhssms: 0,
      syncloading: false,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
    listProdi() {
      return this.$store.getters[`MasterProdi/list`];
    },
    currentFakultas() {
      return this.$store.state.main.currentFakultas;
    },
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
  },
  watch: {
    //update table if data changes
    dataTable: {
      handler: function (newData) {
        if (
          this.tabulator.getDataCount() !== newData.length &&
          newData.length > 0
        ) {
          this.tabulator.replaceData(newData);
        } else if (newData.length < this.tabulator.getDataCount()) {
          this.tabulator.replaceData(newData);
        }
      },
      deep: true,
    },
    currentInstitusi: {
      handler: function () {
        this.filtering();
      },
    },
  },
  mounted() {
    option.fetchProdi(this.filterData, true);
  },
  methods: {
    deleteRow() {
      this.deleting = true;
      const id = this.idData;
      const store = this.store;
      this.deletingRow.delete().then(function () {
        //run code after row has been deleted
        store.dispatch("MataKuliah/delete", id);
      });
      cash("#delete-modal").modal("hide");
    },
    getItems() {
      this.deleting = false;
      this.editing = false;
      this.tabulator.setData(
        mahasiswa.RegistasiPD.endpoint,
        {},
        tblTabulator.ajaxConfig()
      );
    },
    filterProdi(id) {
      const prodi = this.listProdi.filter((item) => item.id === id);
      const cekfilter = this.filterData?.prodi.data.filter(
        (item) => item.id === id
      );
      if (cekfilter.length === 0) {
        this.filterData?.prodi.data.push(prodi[0]);
      } else {
        let index = this.filterData?.prodi.data.findIndex(
          (item) => item.id === id
        );
        this.filterData?.prodi.data.splice(index, 1);
      }

      this.filtering();
    },

    filtering() {
      if (
        this.filterData.prodi?.data.length > 0 ||
        this.filterData.kelompokMK?.data.length > 0 ||
        this.filterData.jenisMK?.data.length > 0
      ) {
        let filter = [];
        this.filterData?.prodi?.data.forEach((f) => {
          filter.push({ id_sms: f.id_sms });
        });

        this.deleting = false;
        this.editing = false;
        this.tabulator.setData(
          mahasiswa.RegistasiPD.endpoint + "/filterprodi",
          {
            filter: filter,
          },
          tblTabulator.ajaxConfig()
        );
      } else {
        this.getItems();
      }
    },
    syncMhs() {
      if (this.mhssms !== 0) {
        this.syncloading = true;
        mahasiswa.PesertaDidik.syncData("pre_sync", {
          id_sms: this.mhssms,
          angkatan: this.syncangkatan,
          last_npm: this.synclastnipd,
          last_error: this.synclasterror ? 1 : 0,
          token: this.$store.getters["feederpddikti/feedertoken"],
        })
          .then((response) => {
            this.presyncData = [];
            let data = response?.data?.data;
            data.forEach((sync, key) => {
              this.presyncData[key] = {
                npm: sync.nipd,
                id: sync.id_reg_pd,
                result: "Loading ...",
              };
            });
            this.resetSync();
          })
          .catch(() => {
            this.syncloading = false;
          })
          .finally(() => {
            this.presyncData.forEach((sync, key) => {
              setTimeout(() => {
                mahasiswa.PesertaDidik.syncData("sync", {
                  id_sms: this.mhssms,
                  angkatan: this.syncangkatan,
                  id_reg_pd: sync.id,
                  token: this.$store.getters["feederpddikti/feedertoken"],
                })
                  .then((response) => {
                    this.syncing++;
                    let data = response?.data;
                    if (data?.error_code == "0") {
                      this.presyncData[key].result = "Sukses";
                      this.syncsuccess++;
                    } else {
                      this.syncfailed++;
                      this.presyncData[key].result = data?.error_desc
                        ? data?.error_desc
                        : "Sync failed";
                    }
                  })
                  .finally(() => {
                    this.syncloading = false;
                  });
              }, key * 1000);
            });
          });
      }
    },
    syncRegPD() {
      if (this.mhssms !== 0) {
        this.syncloading = true;
        mahasiswa.RegistasiPD.syncData("pre_sync", {
          id_sms: this.mhssms,
          angkatan: this.syncangkatan,
          last_error: this.synclasterror ? 1 : 0,
          token: this.$store.getters["feederpddikti/feedertoken"],
        })
          .then((response) => {
            let data = response?.data?.data;
            this.presyncData = [];
            data.forEach((sync, key) => {
              this.presyncData[key] = {
                npm: sync.nipd,
                id: sync.id_reg_pd,
                result: "Loading ...",
              };
            });
            this.resetSync();
          })
          .finally(() => {
            this.presyncData.forEach((sync, key) => {
              setTimeout(() => {
                mahasiswa.RegistasiPD.syncData("sync", {
                  id_sms: this.mhssms,
                  angkatan: this.syncangkatan,
                  id_reg_pd: sync.id,
                  token: this.$store.getters["feederpddikti/feedertoken"],
                })
                  .then((response) => {
                    this.syncing++;
                    let data = response?.data;
                    if (data?.error_code == "0") {
                      this.presyncData[key].result = "Sukses";
                      this.syncsuccess++;
                    } else {
                      this.syncfailed++;
                      this.presyncData[key].result = data?.error_desc
                        ? data?.error_desc
                        : "Sync failed";
                    }
                  })
                  .finally(() => {
                    this.syncloading = false;
                  });
              }, key * 1000);
            });
          });
      }
    },
    syncSimponi() {
      if (this.mhssms !== 0) {
        this.syncloading = true;
        mahasiswa.RegistasiPD.syncData("syncSimponi", {
          id_sms: this.mhssms,
          angkatan: this.syncangkatan,
          token: this.$store.getters["feederpddikti/feedertoken"],
        })
          .then((response) => {
            this.sync = true;
            this.syncerrormsg = [];
            let data = response?.data?.data;
            this.syncsuccess = 0;
            this.syncfailed = 0;
            data.forEach((sync, key) => {
              if (sync?.error_code === "0") {
                this.syncsuccess++;
              } else {
                this.syncfailed++;
                if (sync.error_desc !== "") {
                  this.syncerrormsg[key] = sync.error_desc;
                }
              }
            });
          })
          .finally(() => {
            this.syncloading = false;
          });
      }
    },
    selectSyncMhsSms(curr, id) {
      this.mhssms = id;
    },
    resetSync(all = false) {
      this.sync = true;
      this.syncsuccess = 0;
      this.syncfailed = 0;
      this.syncing = 0;
      this.syncloading = false;
      if (all === true) {
        this.presyncData = [];
      }
    },
  },
});
</script>
