<template>
  <div>
    <label v-if="showLabel" for="-institusi-selector">Institusi</label>
    <select
      id="-institusi-selector"
      v-model="institute"
      :class="cssclass"
      @change="setCurrent"
    >
      <option
        v-for="fak in institutions"
        :key="fak.id"
        :value="fak.value"
        :selected="fak.selected"
      >
        {{ fak.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { master } from "@/services/models";

export default {
  name: "InstitusiSelector",
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
    cssclass: {
      type: String,
      default: "w-auto form-select mb-3 box md:w-full",
    },
  },
  data() {
    return {
      institutions: [],
      institute: 0,
      current: null,
    };
  },
  mounted() {
    this.current = this.$store.state.main.currentInstitusi;
    this.fetchInstitusi();
  },
  methods: {
    setCurrent() {
      const cfac = this.institute;
      let curr = this.institutions.filter((item) => item.id === cfac);
      return this.$store.dispatch("main/currentInstitusi", curr[0]);
    },
    fetchInstitusi() {
      let that = this;
      this.institutions.push({
        id: "0",
        value: "0",
        text: "Pilih Institusi",
        selected: false,
      });
      master.MasterInstitusi.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id,
              value: item.id,
              text: item.nama_alias,
              kode_pddikti: item.kode_pddikti,
              nama: item.nama,
              ada_fakultas: item.ada_fakultas,
              id_sp: item.id_sp,
              selected: that.current?.id === item.id,
            };
            if (newoption.selected === true) that.institute = item.id;
            that.institutions.push(newoption);
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
